import { createStore, StoreApi, useStore } from 'zustand';

interface DefaultState {
  type: 'default';
}

interface VotingState {
  type: 'voting';
  id: string;
}

export type PollVotingPanelState = DefaultState | VotingState;
export type PollVotingPanelStore = StoreApi<PollVotingPanelState>;

export function createPollVotingDialogPanelStore(): PollVotingPanelStore {
  return createStore<PollVotingPanelState>(() => ({ type: 'default' }));
}

export function openPollVotingDialog(store: PollVotingPanelStore, id: string): void {
  store.setState(
    {
      type: 'voting',
      id,
    },
    true,
  );
}

export function closePollVotingDialog(store: PollVotingPanelStore): void {
  store.setState(
    {
      type: 'default',
    },
    true,
  );
}

export function usePollVotingPanelState(store: PollVotingPanelStore): PollVotingPanelState {
  return useStore(store);
}
