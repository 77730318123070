import { PropsWithChildren } from 'react';

import spinnerAnimationData from '@/assets/spinner.lottie.json';
import { RMText } from '@/components/RMText/RMText';

import { SelectionContent, SelectionItems, SpinnerAnimation } from './CreatePollOptions.styles';

type CreatePollOptionsProps = PropsWithChildren<{
  loading: boolean;
}>;

export function CreatePollOptions({ loading, children }: CreatePollOptionsProps) {
  return (
    <SelectionContent>
      {loading ? (
        <SpinnerAnimation animationData={spinnerAnimationData} loop />
      ) : (
        <>
          <RMText type="sans" size="xs" color="on-surface-primary">
            Shuffle questions or write your own.
          </RMText>
          <SelectionItems>{children}</SelectionItems>
        </>
      )}
    </SelectionContent>
  );
}
