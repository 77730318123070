import { useMemo, useState } from 'react';

import { PollShareDialogContainer } from '@/modules/sharing/containers/PollShareDialog.container';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { usePollQuery, usePollVotesQuery, useProjectPollsQuery } from '@/services/api/poll';

import { PollList } from '../components/PollList';
import { openPollVotingDialog, PollVotingPanelStore } from '../states/poll-voting-dialog-panel.state';

export type PromptListItemContainerProps = {
  pollId: string;
  onClick: (id: string) => void;
  onShare: (id: string) => void;
};

export function PollListItemContainer({ pollId, onClick, onShare }: PromptListItemContainerProps) {
  const pollQuery = usePollQuery(pollId);
  const user = useUser();
  const authorPersonQuery = usePersonQuery(
    user?.personId === pollQuery.data?.requesterPersonId ? null : pollQuery.data?.requesterPersonId,
  );
  const author =
    user?.personId === pollQuery.data?.requesterPersonId ? 'You' : authorPersonQuery.data?.name?.full ?? '';
  const votesQuery = usePollVotesQuery(pollId);

  if (!pollQuery.data || !votesQuery.data) {
    // TODO-BOOK: Loading/error state
    return null;
  }

  return (
    <PollList.Item
      id={pollQuery.data.id}
      author={author}
      votes={votesQuery.data.length}
      expireOn={pollQuery.data.expireOn}
      onClick={onClick}
      onShare={onShare}
    />
  );
}

export interface PromptListContainerProps {
  projectId: string;
  pollVotingPanelStore: PollVotingPanelStore;
}

export function PollListContainer({ projectId, pollVotingPanelStore }: PromptListContainerProps) {
  const projectPollsIds = useProjectPollsQuery(projectId)?.data;

  const [sharePollId, setSharePollId] = useState<string | null>(null);

  const ListItems = useMemo(() => {
    return projectPollsIds?.map((pollId) => {
      return (
        <PollListItemContainer
          key={pollId}
          pollId={pollId}
          onClick={() => {
            openPollVotingDialog(pollVotingPanelStore, pollId);
          }}
          onShare={() => {
            setSharePollId(pollId);
          }}
        />
      );
    });
  }, [pollVotingPanelStore, projectPollsIds]);

  return (
    <>
      <PollList.Root>{ListItems}</PollList.Root>
      {sharePollId != null && (
        <PollShareDialogContainer
          open
          projectId={projectId}
          pollId={sharePollId}
          onClose={() => setSharePollId(null)}
        />
      )}
    </>
  );
}
