import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';

import { PromptInput } from '../components/PromptInput/PromptInput';
import { PromptEditForm } from '../prompt-edit.form';

export interface PromptInputContainerProps {
  projectId: string;
  form: PromptEditForm;
  onButtonClick?: () => void;
}

export function PromptInputContainer({ projectId, form, onButtonClick }: PromptInputContainerProps) {
  const projectQuery = useProjectQuery(projectId);
  const personQuery = usePersonQuery(projectQuery.data?.notifications.recipientPersonIds[0]);

  return (
    <PromptInput form={form} storytellerName={personQuery.data?.name?.first ?? null} onButtonClick={onButtonClick} />
  );
}
