import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

import { LottieAnimation } from '@/components/LottieAnimation';

export const ShufflerContent = styled(motion.div)`
  position: absolute;
  display: flex;
  width: 100%;
  height: 8rem;
  justify-content: center;
  align-items: center;
  background: var(--surface-bright);
  padding: var(--spacing-lg) var(--spacing-3xl) var(--spacing-lg) var(--spacing-lg);
  border: 1px solid var(--border-weak);
  box-shadow: var(--elevation-bottom-1);
  border-radius: var(--radius-round);
  pointer-events: none;
  opacity: 0;

  top: 50%;
  transform: translateY(calc(-50% + var(--spacing-md) - 1px));
`;

export const ShuffleAnimation = styled(LottieAnimation)`
  position: relative;
  width: 4rem;
  height: 4rem;
  flex: 0 0 4rem;
`;
