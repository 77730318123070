import { createStore, StoreApi, useStore } from 'zustand';

interface DefaultState {
  type: 'default';
}

type AddPromptStep = 'choose-type' | 'text' | 'photo' | 'poll';

interface AddPromptState {
  type: 'add-prompt';
  step: AddPromptStep;
}

interface UpdatePromptState {
  type: 'update-prompt';
  promptId: string;
}

export type ProjectDialogPanelState = DefaultState | AddPromptState | UpdatePromptState;
export type ProjectDialogPanelStore = StoreApi<ProjectDialogPanelState>;

export function createProjectDialogPanelStore(): ProjectDialogPanelStore {
  return createStore<ProjectDialogPanelState>(() => ({ type: 'default' }));
}

export function openAddPromptDialog(store: ProjectDialogPanelStore, initialStep: AddPromptStep = 'choose-type'): void {
  store.setState(
    {
      type: 'add-prompt',
      step: initialStep,
    },
    true,
  );
}

export function setAddPromptDialogStep(store: ProjectDialogPanelStore, step: AddPromptStep): void {
  store.setState(
    {
      type: 'add-prompt',
      step,
    },
    true,
  );
}

export function openUpdatePromptDialog(store: ProjectDialogPanelStore, promptId: string): void {
  store.setState(
    {
      type: 'update-prompt',
      promptId,
    },
    true,
  );
}

export function closePromptDialog(store: ProjectDialogPanelStore): void {
  store.setState(
    {
      type: 'default',
    },
    true,
  );
}

export function useProjectDialogPanelState(store: ProjectDialogPanelStore): ProjectDialogPanelState {
  return useStore(store);
}
