import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { faArrowUpFromBracket, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { AclGroupRole } from '@remento/types/acl';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMDialog } from '@/components/RMDialog';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { toast } from '@/components/RMToast/RMToast';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { PollVoteAvatarListContainer } from '@/modules/poll/containers/PollVoteAvatarList.container';
import { PollShareDialogContainer } from '@/modules/sharing/containers/PollShareDialog.container';
import { useServices } from '@/Services';
import { hasRole, useCurrentUserAclRoles } from '@/services/api/acl';
import { usePersonQuery } from '@/services/api/person';
import { usePollQuery, usePollVotesQuery } from '@/services/api/poll';
import { useProjectQuery } from '@/services/api/project';
import { captureException } from '@/utils/captureException';
import { openShareSheet, showShareSheetToast } from '@/utils/share-sheet';
import { secureUuid } from '@/utils/uuid';

import { PollVotingDialogHeader } from '../components/PollVotingDialogHeader/PollVotingDialogHeader';
import { PollVotingSelection } from '../components/VotingPollSelection/PollVotingSelection';
import { PollVotingOption, usePollVotingOptions } from '../hooks/poll-options.hook';
import {
  closePollVotingDialog,
  PollVotingPanelStore,
  usePollVotingPanelState,
} from '../states/poll-voting-dialog-panel.state';

interface PollVotingLoadingState {
  type: 'loading';
}

interface PollVotingVoteState {
  type: 'vote';
  selectedPollOptionId: string | null;
}

interface PollVotingResultsState {
  type: 'results';
  shareOpen: boolean;
}

interface PollVotingDeleteState {
  type: 'delete';
}

export type PollVotingState =
  | PollVotingLoadingState
  | PollVotingVoteState
  | PollVotingResultsState
  | PollVotingDeleteState;

interface PollVotingContainerProps {
  projectId: string;
  pollVotingPanelStore: PollVotingPanelStore;
}

const EDIT_ROLES = [AclGroupRole.OWNER, AclGroupRole.ADMIN];

export function PollVotingContainer({ projectId, pollVotingPanelStore }: PollVotingContainerProps) {
  const { pollService, pollCacheService, pollAnalyticsService } = useServices();
  const [searchParams] = useSearchParams();
  const panelState = usePollVotingPanelState(pollVotingPanelStore);
  const isMobile = useIsMobileViewport();

  // State
  const [state, setState] = useState<PollVotingState>({ type: 'loading' });

  // Entities
  const pollId = panelState.type === 'voting' ? panelState.id : null;
  const pollQuery = usePollQuery(pollId);
  const pollVotesQuery = usePollVotesQuery(pollId);
  const pollVotingOptions = usePollVotingOptions(pollId);

  const projectQuery = useProjectQuery(projectId);
  const project = projectQuery.data;
  const personQuery = usePersonQuery(project?.notifications.recipientPersonIds[0]);

  const userPollRoles = useCurrentUserAclRoles(pollQuery.data?.acl ?? null);
  const canDelete = hasRole(EDIT_ROLES, userPollRoles ?? []);

  // Set dialog initial state based on user vote state
  useEffect(() => {
    if (pollId == null || pollQuery.data == null) {
      return;
    }

    if (pollQuery.data?.expireOn < Date.now()) {
      toast('The poll is closed.', 'root-toast');
    }

    pollCacheService.hasVoted(pollId).then(async (hasVoted) => {
      const pollIdParam = searchParams.get('pollId');
      const pollState = searchParams.get('pollState');

      if (hasVoted || pollIdParam) {
        setState({ type: 'results', shareOpen: pollState === 'share' });
      } else {
        setState({ type: 'vote', selectedPollOptionId: null });
      }
    });
  }, [pollCacheService, pollId, pollQuery.data, pollVotesQuery.data, searchParams]);

  const resetDialog = useCallback(() => {
    setTimeout(() => {
      setState({ type: 'loading' });
    });
  }, []);

  const handleClose = useCallback(() => {
    resetDialog();
    closePollVotingDialog(pollVotingPanelStore);
  }, [pollVotingPanelStore, resetDialog]);

  const handleDelete = useCallback(async () => {
    if (pollId == null) {
      return;
    }

    try {
      await pollCacheService.deletePoll(pollId);
      pollAnalyticsService.onPollDeleted();
      toast('Poll deleted.', 'root-toast');
      handleClose();
    } catch (error) {
      captureException(error, true);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
    }
  }, [handleClose, pollAnalyticsService, pollCacheService, pollId]);

  const handleVote = useCallback(async () => {
    if (state.type != 'vote' || state.selectedPollOptionId == null || pollId == null) {
      return;
    }

    try {
      await pollCacheService.createPollVote({
        id: secureUuid(),
        pollId,
        promptId: state.selectedPollOptionId,
      });
      pollAnalyticsService.onPollVoted();

      setState({ type: 'results', shareOpen: false });
    } catch (error) {
      captureException(error, true);
      toast('Failed to vote', 'root-toast', 'error');
    }
  }, [pollAnalyticsService, pollCacheService, pollId, state]);

  const handleSelectPollOption = useCallback((option: PollVotingOption) => {
    setState({ type: 'vote', selectedPollOptionId: option.id });
  }, []);

  const handleShare = useCallback(async () => {
    if (pollId == null || state.type != 'results') {
      return;
    }

    if (isMobile) {
      const shareLinkPromise = pollService.getPollShareLink(pollId).then((s) => s.link);
      showShareSheetToast(await openShareSheet({ url: shareLinkPromise }));
      handleClose();
    } else {
      setState({ ...state, shareOpen: true });
    }
  }, [handleClose, isMobile, pollId, pollService, state]);

  if (pollId == null) {
    return null;
  }

  const shareIsOpen = state.type === 'results' && state.shareOpen == true;

  return (
    <>
      <RMDialog.Root
        open={panelState.type === 'voting' && state.type !== 'delete' && !shareIsOpen}
        variant={isMobile ? 'full-screen' : 'regular'}
      >
        <RMDialog.Content>
          {state.type === 'loading' && <RMDialog.Loader />}
          {(state.type === 'vote' || state.type === 'results') && (
            <>
              <PollVotingDialogHeader
                expireOn={pollQuery.data?.expireOn ?? 0}
                LeftAdornment={
                  canDelete ? (
                    <RMIconButton
                      icon={faTrash}
                      tooltip={{ label: 'Delete', position: 'bottom' }}
                      color="white"
                      backgroundColor="transparent"
                      onClick={() => setState({ type: 'delete' })}
                    />
                  ) : null
                }
                RightAdornment={<RMCloseButton color="white" onClick={handleClose} />}
              />

              <RMDialog.Body>
                <PollVotingSelection
                  state={state.type}
                  subjectName={personQuery.data?.name ?? null}
                  options={pollVotingOptions ?? []}
                  selectedOptionId={state.type == 'vote' ? state.selectedPollOptionId : null}
                  onSelect={handleSelectPollOption}
                  PollVotesList={<PollVoteAvatarListContainer pollId={pollId} />}
                />
              </RMDialog.Body>

              <RMDialog.Footer>
                {state.type === 'vote' && (
                  <RMButton
                    background="primary"
                    fullWidth
                    disabled={state.selectedPollOptionId == null}
                    autoLoading
                    onClick={handleVote}
                  >
                    Submit vote
                  </RMButton>
                )}

                {state.type === 'results' && (
                  <RMButton leftIcon={faArrowUpFromBracket} background="primary" fullWidth onClick={handleShare}>
                    Share poll
                  </RMButton>
                )}
              </RMDialog.Footer>
            </>
          )}
        </RMDialog.Content>
      </RMDialog.Root>

      {state.type === 'results' && (
        <PollShareDialogContainer open={state.shareOpen} projectId={projectId} pollId={pollId} onClose={handleClose} />
      )}

      <RMConfirmationModal
        open={state.type === 'delete'}
        title="Delete poll?"
        message="This will remove the poll and all current and future votes."
        confirmLabel="Yes, delete"
        type="danger"
        onConfirm={handleDelete}
        onCancel={() => {
          setState({ type: 'vote', selectedPollOptionId: null });
        }}
      />
    </>
  );
}
