import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

import { RMText } from '@/components/RMText/RMText';

export const PollOptionContent = styled(motion.div)`
  width: 100%;
`;

export const PollOptionLabel = styled(RMText)`
  padding-left: var(--spacing-2xs);
`;

export const PollOptionQuestion = styled.div`
  display: flex;
  width: 100%;
  height: 8rem;
  align-items: center;
  background: var(--surface-bright);
  padding: var(--spacing-lg) var(--spacing-3xl) var(--spacing-lg) var(--spacing-lg);
  border: 1px solid var(--border-weak);
  box-shadow: var(--elevation-bottom-1);
  border-radius: var(--radius-round);
  position: relative;
  transition: background 150ms ease;
  cursor: pointer;

  &:hover {
    background-color: var(--darken-neutral-hover);
  }

  &:active {
    background-color: var(--darken-neutral-press);
  }
`;

export const EditIcon = styled(FontAwesomeIcon)`
  position: absolute;
  padding: var(--spacing-2xs);
  top: var(--spacing-sm);
  right: var(--spacing-sm);
`;
