import { faShuffle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ShuffleAnimationData from '@/assets/shuffle.lottie.json';

import { ShuffleAnimation, ShufflerContent } from './PollOptionShuffler.styles';

interface PollOptionProps {
  show: boolean;
}

const animation = {
  opacity: [0, 0, 1, 1, 1, 0, 0],
};

export function PollOptionShuffler({ show = false }: PollOptionProps) {
  return (
    <ShufflerContent animate={show && animation} transition={{ duration: 2, ease: 'easeInOut' }}>
      <ShuffleAnimation animationData={ShuffleAnimationData} />
    </ShufflerContent>
  );
}
