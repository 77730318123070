import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { faRecordVinyl, faSend, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { faEllipsis, faLink } from '@fortawesome/pro-solid-svg-icons';
import { ImageAssetAlternativeType } from '@remento/types/alternative';
import { AssetType } from '@remento/types/asset';
import { EntityType } from '@remento/types/entity';
import { ProjectStatus, PromptStatus, PromptType } from '@remento/types/project';

import { BottomSheetOption, RMBottomSheet } from '@/components/RMBottomSheet';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMDialog } from '@/components/RMDialog';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { toast } from '@/components/RMToast/RMToast';
import { setInputValue } from '@/modules/form/input';
import { RecordingStorytellerSelectionDialogContainer } from '@/modules/recording/containers/RecordingStorytellerSelectionDialog.container';
import { getRecordingIntroPath } from '@/modules/routing';
import { windowOpen } from '@/modules/routing/utils/window';
import { useServices } from '@/Services';
import { useAssetQuery } from '@/services/api/asset';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { EntityMutation } from '@/services/api/cache';
import { usePersonQuery } from '@/services/api/person';
import {
  useProjectQuery,
  usePromptFirstQuestionQuery,
  usePromptImageUrl,
  usePromptQuery,
} from '@/services/api/project';
import { captureException } from '@/utils/captureException';
import { FilestackHandle } from '@/utils/filestack';
import { openShareSheet, showShareSheetToast } from '@/utils/share-sheet';

import { getFormValue, isFormDirty, isFormValid, useIsFormValid } from '../../form/form';
import { PromptEdit } from '../components/PromptEdit/PromptEdit';
import { createPromptEditForm } from '../prompt-edit.form';
import {
  closePromptDialog,
  ProjectDialogPanelStore,
  useProjectDialogPanelState,
} from '../states/project-dialog-panel.state';

export interface PromptUpdateContainerProps {
  store: ProjectDialogPanelStore;
}

export function PromptUpdateContainer({ store }: PromptUpdateContainerProps) {
  const initialForm = useMemo(() => createPromptEditForm(), []);
  const form = useRef(initialForm);
  const formValid = useIsFormValid(form.current);
  const dialogState = useProjectDialogPanelState(store);

  // Services
  const { assetService, entityCacheManagerService, projectService, projectCacheService, promptAnalyticsService } =
    useServices();

  // Entities
  const promptQuery = usePromptQuery(dialogState.type === 'update-prompt' ? dialogState.promptId : null);
  const questionQuery = usePromptFirstQuestionQuery(dialogState.type === 'update-prompt' ? dialogState.promptId : null);
  const assetQuery = useAssetQuery(promptQuery.data?.type === PromptType.PHOTO ? promptQuery.data?.imagesIds[0] : null);
  const promptImageUrl = usePromptImageUrl(promptQuery.data ?? null, ImageAssetAlternativeType.SMALL);
  const projectQuery = useProjectQuery(promptQuery.data?.projectId);
  const storytellerQuery = usePersonQuery(projectQuery.data?.subjectPersonIds?.[0]);

  const user = useUser();
  const userIsRecipient = projectQuery.data?.notifications.recipientPersonIds.includes(user?.personId ?? '');
  const showRecord =
    promptQuery.data?.status === PromptStatus.SENT ||
    (promptQuery.data?.status === PromptStatus.PENDING && userIsRecipient);

  // Photo
  const [changedPhoto, setChangedPhoto] = useState<{ url: string; handle: string } | null>(null);
  const [deletePromptConfirmationOpen, setDeletePromptConfirmationOpen] = useState(false);

  const [recordingStorytellerSelectorDialogOpen, setRecordingStorytellerSelectorDialogOpen] = useState(false);

  const title = useMemo(() => {
    if (projectQuery.data?.configuration.topics.length == 0) {
      return 'Your photo';
    }
    return promptQuery.data?.status === PromptStatus.SENT ? 'Sent prompt' : 'Edit prompt';
  }, [projectQuery.data?.configuration.topics.length, promptQuery.data?.status]);

  const readOnly = useMemo(() => {
    return promptQuery.data?.status === PromptStatus.SENT && projectQuery.data?.configuration.timePeriod === 'PAST';
  }, [projectQuery.data?.configuration.timePeriod, promptQuery.data?.status]);

  const closeAndResetDialog = useCallback(() => {
    closePromptDialog(store);
    setChangedPhoto(null);
    form.current = createPromptEditForm();
  }, [store]);

  const handlePhotoChange = useCallback(
    (photo: FilestackHandle) => {
      setChangedPhoto(photo);
      toast('Photo uploaded', 'root-toast');
      promptAnalyticsService.onPromptPhotoSelected();
    },
    [promptAnalyticsService],
  );

  const handlePhotoPickerOpen = useCallback(() => {
    promptAnalyticsService.onPromptPhotoPickerOpened('prompt');
  }, [promptAnalyticsService]);

  const handleSave = useCallback(async () => {
    // TODO-BOOK: Loading state?
    const formValues = getFormValue(form.current);
    if (!isFormValid(form.current) || formValues === null) {
      return;
    }

    try {
      if (!questionQuery.data) {
        console.warn('Question not loaded yet');
        return;
      }
      if (!promptQuery.data) {
        console.warn('Prompt not loaded yet');
        return;
      }

      const prompt = promptQuery.data;
      const question = questionQuery.data;

      const mutations: EntityMutation[] = [];

      // Update photo
      if (changedPhoto) {
        const newAsset = await assetService.createFilestackAsset({
          entity: { id: prompt.id, type: EntityType.PROMPT },
          type: AssetType.IMAGE,
          handle: changedPhoto.handle,
        });

        if (prompt.type === PromptType.PHOTO) {
          mutations.push(...projectService.createRemovePromptImageMutation(prompt, prompt.imagesIds[0]));
        }
        mutations.push(...projectService.createAddPromptImageMutation(prompt, newAsset.id));

        const asset = assetQuery.data;
        if (asset) {
          mutations.push(...assetService.createUnlinkEntityMutation(asset, { id: prompt.id, type: EntityType.PROMPT }));
        }
      }

      mutations.push(...projectService.createSetQuestionTextMutation(question, formValues.question));

      // Run mutations
      await entityCacheManagerService.mutate(mutations);

      closeAndResetDialog();
      toast('Prompt updated');

      // Analytics
      promptAnalyticsService.onPromptActionPerformed('save');
    } catch (error) {
      captureException(error, true);
      toast('Failed to update prompt', 'root-toast', 'error');
    }
  }, [
    assetQuery.data,
    assetService,
    changedPhoto,
    form,
    entityCacheManagerService,
    promptAnalyticsService,
    promptQuery.data,
    questionQuery.data,
    projectService,
    closeAndResetDialog,
  ]);

  // Close
  const [closeConfirmationOpen, setCloseConfirmationOpen] = useState(false);

  const handleSaveChanges = useCallback(() => {
    setCloseConfirmationOpen(false);
    handleSave();
    promptAnalyticsService.onPromptActionPerformed('discord-no');
  }, [handleSave, promptAnalyticsService]);

  const handleDiscardChanges = useCallback(() => {
    setCloseConfirmationOpen(false);
    closeAndResetDialog();
    promptAnalyticsService.onPromptActionPerformed('discard-yes');
  }, [closeAndResetDialog, promptAnalyticsService]);

  const handleClose = useCallback(() => {
    // If we have pending changes, ask the user if they want to discard
    if (isFormDirty(form.current)) {
      setCloseConfirmationOpen(true);
      return;
    }
    // otherwise, just close the dialog directly
    closeAndResetDialog();
    promptAnalyticsService.onPromptActionPerformed('close');
  }, [closeAndResetDialog, promptAnalyticsService]);

  const handleDeletePrompt = useCallback(() => {
    promptAnalyticsService.onPromptActionPerformed('delete-prompt');
    setDeletePromptConfirmationOpen(true);
  }, [promptAnalyticsService]);

  const handleConfirmPromptDeletion = useCallback(async () => {
    if (!promptQuery.data) {
      return;
    }

    const mutations = projectService.createDeletePromptMutation(promptQuery.data);
    await projectCacheService.deletePrompt(promptQuery.data.id, mutations);
    toast('Prompt deleted');

    // otherwise, just close the dialog directly
    setDeletePromptConfirmationOpen(false);
    closeAndResetDialog();
    promptAnalyticsService.onPromptActionPerformed('close');
  }, [promptQuery.data, closeAndResetDialog, projectService, projectCacheService, promptAnalyticsService]);

  // Send prompt now
  const [sendPromptNowModalOpen, setSendPromptNowModalOpen] = useState(false);

  const authorPersonQuery = usePersonQuery(promptQuery.data?.requesterPersonId);

  const handleSendPromptNow = useCallback(async () => {
    if (dialogState.type !== 'update-prompt') {
      return;
    }

    try {
      const cachedPrompt = await projectCacheService.getPrompt(dialogState.promptId);
      if (!cachedPrompt) {
        throw new Error('Missing cached prompt with ID ' + dialogState.promptId);
      }

      const mutations = projectService.createSetSentMutation(cachedPrompt);
      await entityCacheManagerService.mutate(mutations);

      setSendPromptNowModalOpen(false);
      closeAndResetDialog();
      toast('Your prompt will be sent shortly');
    } catch (error) {
      captureException(error, true);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
    }
  }, [closeAndResetDialog, dialogState, entityCacheManagerService, projectCacheService, projectService]);

  const copyRecordingLink = useCallback(async () => {
    if (!promptQuery.data?.id) {
      toast('An unexpected error has occurred', 'root-toast', 'error');
      return;
    }

    try {
      toast('Generating link...', 'root-toast', 'success');
      const urlPromise = projectService.getPromptRecordLink(promptQuery.data.id);

      showShareSheetToast(await openShareSheet({ url: urlPromise }));
    } catch (error) {
      captureException(error, true);
      toast('An unexpected error has occurred', 'root-toast', 'error');
    }
  }, [promptQuery.data?.id, projectService]);

  const goToRecord = useCallback(
    (recorderPersonId?: string) => {
      if (promptQuery.data == null) {
        return;
      }

      const searchParams = new URLSearchParams();
      if (recorderPersonId != null) {
        searchParams.set('recorder-person-id', recorderPersonId);
      }

      windowOpen(getRecordingIntroPath(promptQuery.data.projectId, promptQuery.data.id, searchParams), '_blank');
      promptAnalyticsService.onPromptActionPerformed('record-now');
    },
    [promptQuery.data, promptAnalyticsService],
  );

  const handleRecordNow = useCallback(() => {
    if (promptQuery.data == null) {
      return;
    }

    const userIsStoryteller =
      storytellerQuery.data == null || user?.personId == null || storytellerQuery.data.refIds.includes(user.personId);

    if (userIsStoryteller) {
      goToRecord(promptQuery.data.id);
    } else {
      setRecordingStorytellerSelectorDialogOpen(true);
    }
  }, [promptQuery.data, storytellerQuery.data, user, goToRecord]);

  // Update the form value
  useEffect(() => {
    if (!questionQuery.data) {
      return;
    }
    setInputValue(form.current, 'question', questionQuery.data.text ?? '');
  }, [form, questionQuery.data]);

  // Analytics
  useEffect(() => {
    if (dialogState.type === 'update-prompt' && promptQuery.data?.status) {
      promptAnalyticsService.onPromptArrived(
        promptQuery.data.status === PromptStatus.SENT ? 'in-progress' : 'upcoming',
      );
    }
  }, [dialogState.type, promptAnalyticsService, promptQuery.data?.status]);

  const handleRecordingStorytellerSelectionDialogConfirm = useCallback(
    (recorderPersonId: string) => {
      goToRecord(recorderPersonId);
      setRecordingStorytellerSelectorDialogOpen(false);
    },
    [goToRecord],
  );

  const handleCloseRecordingStorytellerSelectionDialog = useCallback(() => {
    setRecordingStorytellerSelectorDialogOpen(false);
  }, []);

  const photoSrc = changedPhoto?.url || promptImageUrl;

  const promptOptions = useMemo(() => {
    const options: BottomSheetOption[] = [];

    if (projectQuery.data?.status === ProjectStatus.ACTIVE) {
      if (showRecord) {
        options.push({
          label: 'Record now',
          value: 'record-now',
          icon: faRecordVinyl,
          onSelect: handleRecordNow,
        });
      }

      if (!userIsRecipient) {
        options.push({
          label: promptQuery.data?.status === PromptStatus.SENT ? 'Resend prompt now' : 'Send prompt now',
          value: 'send-prompt',
          icon: faSend,
          onSelect: () => {
            setSendPromptNowModalOpen(true);
            promptAnalyticsService.onPromptActionPerformed(
              promptQuery.data?.status === PromptStatus.SENT ? 'resend-prompt' : 'send-now',
            );
          },
        });
      }

      if (promptQuery.data?.status === PromptStatus.SENT) {
        options.push({
          label: 'Copy recording link',
          value: 'copy-link',
          icon: faLink,
          onSelect: copyRecordingLink,
        });
      }
    }

    options.push({
      label: 'Delete prompt',
      value: 'delete-prompt',
      icon: faTrashCan,
      onSelect: handleDeletePrompt,
      destructive: true,
    });

    return options;
  }, [
    projectQuery.data?.status,
    promptQuery.data?.status,
    showRecord,
    userIsRecipient,
    handleRecordNow,
    promptAnalyticsService,
    copyRecordingLink,
    handleDeletePrompt,
  ]);

  if (!promptQuery.data) {
    return null;
  }

  return (
    <RMDialog.Root variant={'full-screen'} open={dialogState.type === 'update-prompt'} onClose={handleClose}>
      <RMDialog.Content>
        <RMDialog.Header
          title={title}
          rightAdornment={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 'var(--spacing-xs)',
              }}
            >
              <RMBottomSheet
                Trigger={
                  <RMIconButton icon={faEllipsis} size="xl" tooltip={null} backgroundColor="transparent" as="div" />
                }
                options={promptOptions}
              />
              <RMCloseButton onClick={handleClose} />
            </div>
          }
        ></RMDialog.Header>
        <RMDialog.Body>
          {/* TODO-BOOK: Loading? */}
          {questionQuery.data ? (
            <PromptEdit
              form={form.current}
              photoUrl={photoSrc}
              readOnly={readOnly}
              onPhotoChange={handlePhotoChange}
              onOpenPhotoPicker={handlePhotoPickerOpen}
              timePeriod={projectQuery.data?.configuration.timePeriod}
              authorFirstName={authorPersonQuery.data?.name?.first}
              promptStatus={promptQuery.data.status}
              sentOn={promptQuery.data.sentOn}
            />
          ) : null}
        </RMDialog.Body>
        <RMDialog.Footer>
          {readOnly ? (
            <RMButton
              leftIcon={userIsRecipient ? faRecordVinyl : faLink}
              background="primary"
              fullWidth
              onClick={userIsRecipient ? handleRecordNow : copyRecordingLink}
              autoLoading
            >
              {userIsRecipient ? 'Start recording' : 'Copy recording link'}
            </RMButton>
          ) : (
            <RMButton background="primary" fullWidth onClick={handleSave} autoLoading disabled={!formValid}>
              Save
            </RMButton>
          )}
        </RMDialog.Footer>

        <RMConfirmationModal
          open={closeConfirmationOpen}
          type="primary"
          title="Save changes?"
          message="You have unsaved changes on this page. Would you like to save them?"
          confirmLabel="Save changes"
          cancelLabel="Discard"
          onConfirm={handleSaveChanges}
          onCancel={handleDiscardChanges}
        />

        <RMConfirmationModal
          open={deletePromptConfirmationOpen}
          type="danger"
          title="Remove prompt"
          message="This action cannot be undone."
          cancelLabel="Cancel"
          confirmLabel="Delete prompt"
          onCancel={() => setDeletePromptConfirmationOpen(false)}
          onConfirm={handleConfirmPromptDeletion}
        />

        <RMConfirmationModal
          open={sendPromptNowModalOpen}
          title="Send prompt now?"
          message={`Proceeding will immediately send ${storytellerQuery.data?.name?.first} this prompt.`}
          cancelLabel="Nevermind"
          confirmLabel="Send now"
          onConfirm={handleSendPromptNow}
          onCancel={() => setSendPromptNowModalOpen(false)}
          onClose={() => setSendPromptNowModalOpen(false)}
        />

        <RecordingStorytellerSelectionDialogContainer
          projectId={projectQuery.data?.id}
          open={recordingStorytellerSelectorDialogOpen}
          onClose={handleCloseRecordingStorytellerSelectionDialog}
          onContinue={handleRecordingStorytellerSelectionDialogConfirm}
        />
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
