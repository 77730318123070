import { styled } from '@linaria/react';

import { LottieAnimation } from '@/components/LottieAnimation';

export const SharePollContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xs);
  height: 100%;
  position: relative;
`;

export const Timer = styled.div`
  display: flex;
  gap: 0.875rem;

  span {
    width: 2rem;
  }
`;

export const ArrowAnimation = styled(LottieAnimation)`
  position: relative;
  width: 2rem;
  height: 2rem;
  flex: 0 0 2rem;

  body[data-mobile='true'] & {
    position: absolute;
    bottom: 0;
    margin-bottom: var(--spacing-xl);
  }
`;
