import { styled } from '@linaria/react';

import { LottieAnimation } from '@/components/LottieAnimation';

export const SelectionContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

export const SelectionItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-lg);
  min-height: 20rem;
  position: relative;
  padding-top: var(--spacing-lg);

  body[data-mobile='true'] & {
    padding: 0;
    justify-content: center;
    height: 100%;
  }
`;

export const SpinnerAnimation = styled(LottieAnimation)`
  position: relative;
  width: 2rem;
  height: 2rem;
  flex: 0 0 2rem;
`;
