import { useCallback, useEffect, useMemo } from 'react';
import { PromptType } from '@remento/types/project';

import { setInputValue } from '@/modules/form/input';
import { PromptEdit } from '@/modules/project/components/PromptEdit/PromptEdit';
import { PromptEditForm } from '@/modules/project/prompt-edit.form';
import { PromptDraftsStore, usePromptDraft } from '@/modules/project/states/prompt-drafts.state';
import { useServices } from '@/Services';
import { FilestackHandle } from '@/utils/filestack';

interface UpdatePromptTemplateContainerProps {
  promptId: string;
  promptDraftsStore: PromptDraftsStore;
  form: PromptEditForm;
  photo: string | null;
  onPhotoChange: (photo: FilestackHandle) => void;
}

export function UpdatePromptTemplateContainer({
  promptId,
  promptDraftsStore,
  form,
  photo,
  onPhotoChange,
}: UpdatePromptTemplateContainerProps) {
  const { promptAnalyticsService } = useServices();

  const prompt = usePromptDraft(promptDraftsStore, promptId);
  const photoUrl = useMemo(() => {
    if (photo) {
      return photo;
    }
    if (prompt?.type === PromptType.PHOTO) {
      return prompt.photo.url;
    }
    return null;
  }, [photo, prompt]);

  const handlePhotoChange = useCallback(
    (photo: FilestackHandle) => {
      onPhotoChange(photo);
      promptAnalyticsService.onPromptPhotoSelected();
    },
    [onPhotoChange, promptAnalyticsService],
  );

  const handlePhotoPickerOpen = useCallback(() => {
    promptAnalyticsService.onPromptPhotoPickerOpened('prompt-bank');
  }, [promptAnalyticsService]);

  useEffect(() => {
    setInputValue(form, 'question', prompt?.question ?? '');
  }, [prompt, form]);

  return (
    <PromptEdit
      form={form}
      photoUrl={photoUrl}
      onPhotoChange={handlePhotoChange}
      onOpenPhotoPicker={handlePhotoPickerOpen}
    />
  );
}
