import { ReactNode } from 'react';

import { RMStack } from '@/components/RMStack/RMStack';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { PromptTemplateTag } from '@/services/cms/prompt-template-tags/prompt-template-tags.types';

import { PromptTemplateTagHero } from '../PromptTemplateTagHero/PromptTemplateTagHero';

interface AddPromptsTagScreenProps {
  tag: PromptTemplateTag;
  PromptTemplateList: ReactNode;
}

export function AddPromptsTagScreen({ tag, PromptTemplateList }: AddPromptsTagScreenProps) {
  const isMobile = useIsMobileViewport();

  return (
    <RMStack direction="column" spacing={isMobile ? 'md' : '2xl'}>
      <PromptTemplateTagHero tag={tag} />

      {PromptTemplateList}
    </RMStack>
  );
}
