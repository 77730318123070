import { styled } from '@linaria/react';

export const AddPromptsDialogFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > * {
    flex: 1;
  }
`;
