import { RMInput } from '@/components/RMInput/RMInput';
import { RMInputCharCounter } from '@/components/RMInput/RMInputCharCounter';
import { InputContainer } from '@/modules/form/container';
import { useInputValue } from '@/modules/form/input';

import { PollEditForm } from '../../poll-edit.form';

import { CreatePollEditContent } from './CreatePollEdit.styles';

interface CreatePollEditProps {
  form: PollEditForm;
}

export function CreatePollEdit({ form }: CreatePollEditProps) {
  const question = useInputValue(form, 'question');

  return (
    <CreatePollEditContent>
      <InputContainer form={form} path="question">
        {(props) => <RMInput id="poll-dialog-custom-field" validation={false} textarea fontFamily="sans" {...props} />}
      </InputContainer>

      <RMInputCharCounter length={question?.length ?? 0} maxLength={120} />
    </CreatePollEditContent>
  );
}
