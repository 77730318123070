import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';
export interface PollEditFormInput {
  question: string;
}
export type PollEditForm = Form<PollEditFormInput>;

export function createPollEditFormSchema() {
  return z.object({
    question: z
      .string()
      .min(1, { message: 'This is a required field' })
      .max(120, { message: 'Question should contain at most 120 characters' })
      .nonempty(),
  });
}

export function createPollEditForm(defaultValues?: DeepPartial<PollEditFormInput>): PollEditForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createPollEditFormSchema(),
  });
}
