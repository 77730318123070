import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const InputWrapper = styled.div<{
  showSuccess: boolean;
}>`
  display: flex;
  flex-flow: column;
  width: 100%;
  position: relative;
  box-sizing: border-box;

  &[data-mobile='true'] {
  }

  textarea {
    min-height: 108px;
    opacity: ${({ showSuccess }) => (showSuccess ? '0' : '1')};
  }

  button {
    opacity: ${({ showSuccess }) => (showSuccess ? '0' : '1')};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: var(--spacing-md);
`;

export const SuccessMessageWrapper = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: var(--radius-round);
  background: var(--surface-dim);
  outline: 1px solid var(--surface-dim);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const SuccessMessageLabel = styled.span`
  font-family: var(--font-sans);
  font-size: 1rem;
  color: var(--on-surface-secondary);
  font-weight: 700;
`;

export const LottieWrapper = styled.div`
  width: 80px;
`;
