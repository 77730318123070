import { styled } from '@linaria/react';

export const PromptActionsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: var(--spacing-lg);

  &[data-mobile='true'] {
    flex-flow: column;
    gap: var(--spacing-sm);
  }
`;

export const AddPromptMobile = styled.div`
  margin-bottom: var(--spacing-xl);

  button {
    width: 100%;
  }
`;

export const PromptListWrapper = styled.div`
  body[data-mobile='true'] & {
    margin: 0 calc(var(--spacing-md) * -1);
  }
`;
