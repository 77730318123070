import ConffetiAnimationData from '@/@assets/conffeti-lottie-animation.json';

import { CelebrationText, ConfettiAnimation, CreatePollCelebrationContent } from './CreatePollCelebration.styles';

export function CreatePollCelebration() {
  return (
    <CreatePollCelebrationContent>
      <CelebrationText align="center" type="sans" size="m" bold color="on-surface-primary">
        Your poll is live!
      </CelebrationText>

      <ConfettiAnimation animationData={ConffetiAnimationData} loop />
    </CreatePollCelebrationContent>
  );
}
