import { RMLink } from '@/components/RMLink/RMLink';
import { RMText } from '@/components/RMText/RMText';

import { Item, ItemActions, ItemCenter, Tag } from './PromptReviewListItem.styles';

type PromptTemplateReviewListItemProps = {
  question: string;
  photoSrc: string;
  isEdited?: boolean;
  onEditPrompt: () => void;
  onRemovePrompt: () => void;
};

export function PromptReviewListItem({
  question,
  photoSrc,
  isEdited = false,
  onEditPrompt,
  onRemovePrompt,
}: PromptTemplateReviewListItemProps) {
  return (
    <Item>
      <img src={photoSrc} />

      <ItemCenter>
        {isEdited && (
          <Tag>
            <RMText type="sans" size="xxs" bold trackedOutCaps color="primary">
              CUSTOM
            </RMText>
          </Tag>
        )}

        <RMText type="sans" size="m" color="on-surface-primary">
          {question}
        </RMText>

        <ItemActions>
          <RMLink onClick={onEditPrompt}>Edit</RMLink>
          <RMLink onClick={onRemovePrompt}>Remove</RMLink>
        </ItemActions>
      </ItemCenter>
    </Item>
  );
}
