import { useCallback, useEffect, useMemo, useState } from 'react';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { AssetType } from '@remento/types/asset';
import { EntityType } from '@remento/types/entity';
import { PromptType } from '@remento/types/project';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMDialog } from '@/components/RMDialog';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { toast } from '@/components/RMToast/RMToast';
import { isFormDirty, resetForm, submitForm, useIsFormValid } from '@/modules/form/form';
import { setInputValue } from '@/modules/form/input';
import { useServices } from '@/Services';
import { captureException } from '@/utils/captureException';
import { FilestackHandle } from '@/utils/filestack';
import { secureUuid } from '@/utils/uuid';

import { PromptEdit } from '../components/PromptEdit/PromptEdit';
import { PromptReviewList } from '../components/PromptReviewList';
import { createPromptEditForm } from '../prompt-edit.form';

export interface ReviewPhotoPromptsContainerProps {
  projectId: string;
  photos: FilestackHandle[];
  open: boolean;
  onClose: () => void;
  onPromptsAdded?: () => void;
}

interface Prompt {
  id: string;
  photo: FilestackHandle;
  question: string;
}

interface ReviewStep {
  type: 'review';
}

interface EditStep {
  type: 'edit';
  prompt: Prompt;
}

type Step = ReviewStep | EditStep;

export function ReviewPhotoPromptsContainer({
  projectId,
  photos,
  open,
  onClose,
  onPromptsAdded,
}: ReviewPhotoPromptsContainerProps) {
  const { assetService, projectCacheService } = useServices();

  const [step, setStep] = useState<Step>({ type: 'review' });
  const [direction, setDirection] = useState<'ltr' | 'rtl'>('rtl');

  const [unsavedPromptChangesModalOpen, setUnsavedPromptChangesModalOpen] = useState(false);
  const [unsavedPromptsModalOpen, setUnsavedPromptsModalOpen] = useState(false);
  const [promptToDelete, setPromptToDelete] = useState<string | null>(null);

  const form = useMemo(createPromptEditForm, []);
  const isFormValid = useIsFormValid(form);

  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [newPhoto, setNewPhoto] = useState<FilestackHandle | null>(null);

  // Reset the modal state
  const reset = useCallback(() => {
    setPromptToDelete(null);
    setUnsavedPromptChangesModalOpen(false);
    setUnsavedPromptsModalOpen(false);
    setNewPhoto(null);
    resetForm(form);
  }, [form]);

  // Close the modal
  const handleClose = useCallback(() => {
    setUnsavedPromptsModalOpen(true);
  }, []);

  // Delete the prompt
  const handleConfirmPromptDeletion = useCallback(() => {
    if (promptToDelete === null) {
      return;
    }

    // If we only have one prompt left, close the modal.
    if (prompts.length === 1) {
      onClose();
      reset();
      return;
    }
    setPrompts((currentPrompts) => currentPrompts.filter((p) => p.id !== promptToDelete));
    setPromptToDelete(null);
  }, [onClose, promptToDelete, prompts.length, reset]);

  // Edit a prompt
  const handleEditPrompt = useCallback(
    (prompt: Prompt) => {
      setDirection('rtl');
      setInputValue(form, 'question', prompt.question);
      setTimeout(() => {
        setStep({ type: 'edit', prompt });
      });
    },
    [form],
  );

  // Save prompt changes
  const handleSavePromptChanges = useCallback(async () => {
    if (step.type !== 'edit') {
      return;
    }

    await submitForm(form, ({ question }) => {
      setPrompts((currentPrompts) =>
        currentPrompts.map((p) => {
          if (p.id === step.prompt.id) {
            return {
              ...p,
              question,
              photo: newPhoto ?? p.photo,
            };
          }
          return p;
        }),
      );

      resetForm(form);
      setUnsavedPromptChangesModalOpen(false);
      setNewPhoto(null);
      setDirection('ltr');
      setTimeout(() => {
        setStep({ type: 'review' });
      });
    });
  }, [form, newPhoto, step]);

  // Discard prompt edit changes
  const handleDiscardPromptChanges = useCallback(() => {
    resetForm(form);
    setUnsavedPromptChangesModalOpen(false);
    setNewPhoto(null);
    setDirection('ltr');
    setTimeout(() => {
      setStep({ type: 'review' });
    });
  }, [form]);

  // Go to the previous step
  const handleGoBack = useCallback(() => {
    setDirection('ltr');
    setTimeout(() => {
      switch (step.type) {
        case 'review':
          break;
        case 'edit':
          if (isFormDirty(form) || newPhoto !== null) {
            setUnsavedPromptChangesModalOpen(true);
            return;
          }

          setStep({ type: 'review' });
          break;
      }
    });
  }, [form, newPhoto, step.type]);

  // Discard all changes
  const handleDiscardPrompts = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  // Add the prompts to the queue
  const handleSavePrompts = useCallback(async () => {
    try {
      const assets = await Promise.all(
        prompts.map((prompt) =>
          assetService.createFilestackAsset({
            handle: prompt.photo.handle,
            type: AssetType.IMAGE,
            entity: { type: EntityType.PROMPT, id: prompt.id },
          }),
        ),
      );

      await projectCacheService.createPrompts(
        projectId,
        prompts.map((prompt, index) => ({
          id: prompt.id,
          type: PromptType.PHOTO,
          status: null,
          imagesIds: [assets[index].id],
          question: prompt.question,
        })),
      );
      onClose();
      onPromptsAdded?.();
      reset();
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [assetService, onClose, onPromptsAdded, prompts, reset, projectCacheService, projectId]);

  // Create the prompts drafts
  useEffect(() => {
    setPrompts(
      photos.map((photo) => ({
        id: secureUuid(),
        photo,
        question: 'What is happening in this photo?',
      })),
    );
    setStep({ type: 'review' });
    setDirection('rtl');
  }, [photos]);

  return (
    <RMDialog.Root open={open} onClose={handleClose} animation={direction} variant="full-screen">
      <RMDialog.Content>
        <RMDialog.Header
          title={step.type === 'edit' ? 'Edit prompt' : 'Review your prompts'}
          centerTitle
          divider
          leftAdornment={
            step.type === 'edit' && (
              <RMIconButton
                icon={faChevronLeft}
                tooltip={null}
                backgroundColor="transparent"
                stateColor="darken-neutral"
                onClick={handleGoBack}
              />
            )
          }
          rightAdornment={<RMCloseButton onClick={handleClose} />}
        />

        <RMDialog.Body>
          <RMDialog.Transition key={step.type} animation={direction}>
            {step.type === 'review' && (
              <PromptReviewList.Root>
                {prompts.map((prompt) => (
                  <PromptReviewList.Item
                    key={prompt.id}
                    question={prompt.question}
                    photoSrc={prompt.photo.url}
                    onEditPrompt={() => handleEditPrompt(prompt)}
                    onRemovePrompt={() => setPromptToDelete(prompt.id)}
                  />
                ))}
              </PromptReviewList.Root>
            )}

            {step.type === 'edit' && (
              <PromptEdit form={form} photoUrl={newPhoto?.url ?? step.prompt.photo.url} onPhotoChange={setNewPhoto} />
            )}
          </RMDialog.Transition>
        </RMDialog.Body>

        <RMDialog.Footer>
          {step.type === 'review' && (
            <RMButton background="primary" onClick={handleSavePrompts} fullWidth autoLoading>
              Add {prompts.length} {prompts.length === 1 ? 'prompt' : 'prompts'} to queue
            </RMButton>
          )}

          {step.type === 'edit' && (
            <RMButton background="primary" onClick={handleSavePromptChanges} fullWidth disabled={isFormValid === false}>
              Save
            </RMButton>
          )}
        </RMDialog.Footer>
      </RMDialog.Content>

      <RMConfirmationModal
        open={unsavedPromptsModalOpen}
        title="Discard prompts?"
        message="Your selected prompts will not be saved if you proceed."
        confirmLabel="Discard Prompts"
        type="danger"
        onConfirm={handleDiscardPrompts}
        onCancel={() => {
          setUnsavedPromptsModalOpen(false);
        }}
      />

      <RMConfirmationModal
        open={unsavedPromptChangesModalOpen}
        title="Save changes?"
        message="You have unsaved changes on this page. Would you like to save them?"
        confirmLabel="Save changes"
        cancelLabel="Discard"
        onConfirm={handleSavePromptChanges}
        onCancel={handleDiscardPromptChanges}
        onClose={() => setUnsavedPromptChangesModalOpen(false)}
      />

      <RMConfirmationModal
        open={promptToDelete !== null}
        type="danger"
        title="Remove prompt"
        message="This action cannot be undone."
        cancelLabel="Cancel"
        confirmLabel="Delete Prompt"
        onCancel={() => setPromptToDelete(null)}
        onConfirm={handleConfirmPromptDeletion}
      />
    </RMDialog.Root>
  );
}
