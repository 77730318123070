/**
 * Navigate to a URL.
 * This method is a wrapper around windows.open
 * that will automatically add the base path to the url
 * if the url is relative and does not include the base path already.
 */
export function windowOpen(url: string, target?: string): void {
  const basename: string = import.meta.env.VITE_PATH_BASENAME ?? '';

  if (url.startsWith('/') === false || basename.length === 0 || url.startsWith(basename)) {
    window.open(url, target);
    return;
  }

  window.open(basename + url, target);
}
