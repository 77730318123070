import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { motion } from 'framer-motion';

import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { PollOption } from '@/modules/poll/hooks/poll-options.hook';
import { ColorsTokens } from '@/styles/theme/colorTokens';

import { EditIcon, PollOptionContent, PollOptionLabel, PollOptionQuestion } from './PollOptionItem.styles';

interface PollOptionProps {
  label: string;
  pollOption: PollOption;
  animate: 'ttb' | 'btt' | false;
  selected?: boolean;
  onClick?: () => void;
}

const opacity = [1, 0, 0, 0, 0, 1, 1];

const variants = {
  ttb: {
    y: ['0rem', '5.5rem', '5.5rem', '5.5rem', '5.5rem', '5.5rem', '0rem'],
    opacity,
  },
  btt: {
    y: ['0rem', '-5.5rem', '-5.5rem', '-5.5rem', '-5.5rem', '-5.5rem', '0rem'],
    opacity,
  },
};

export function PollOptionItem({ label, pollOption, animate = false, selected = false, onClick }: PollOptionProps) {
  return (
    <PollOptionContent animate={animate} variants={variants} transition={{ duration: 2, ease: 'easeInOut' }}>
      <PollOptionLabel style={{ opacity: animate ? 0 : 1 }} color="on-surface-secondary" type="sans" size="xs" bold>
        {label}
      </PollOptionLabel>
      <RMSpacer spacing="xs" direction="column" />
      <PollOptionQuestion data-selected={selected} onClick={onClick}>
        <motion.div animate={animate ? { opacity } : false} transition={{ duration: 2, ease: 'easeIn' }}>
          <EditIcon size="sm" icon={faPen} color={ColorsTokens['on-surface-tertiary']} />
          <RMText color="primary" type="sans" size="s" bold>
            {pollOption.question}
          </RMText>
        </motion.div>
      </PollOptionQuestion>
    </PollOptionContent>
  );
}
