import { styled } from '@linaria/react';

export const Header = styled.div`
  background: var(--inverse-surface);
  padding: var(--spacing-xs);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  box-sizing: border-box;
`;

export const CenterText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Timer = styled.div`
  display: flex;
  gap: var(--spacing-md);

  span {
    width: 2.5rem;
  }
`;

export const HiddenFiller = styled.div`
  opacity: 0;
  pointer-events: none;
`;
