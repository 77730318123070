import { styled } from '@linaria/react';

import { LottieAnimation } from '@/components/LottieAnimation';
import { RMText } from '@/components/RMText/RMText';

export const CreatePollCelebrationContent = styled.div`
  position: fixed;
  width: calc(100vw - 21rem);
  height: calc(100vh - 6.5rem);
  left: 20rem;
  top: 6.5rem;
  background: var(--surface);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;

  body[data-mobile='true'] & {
    width: 100vw;
    height: calc(100vh - 3.5rem);
    left: 0;
    top: 3.5rem;
  }
`;

export const CelebrationText = styled(RMText)`
  // to keep centralized with screen (ignoring the header)
  transform: translateY(-7rem);

  body[data-mobile='true'] & {
    transform: translateY(0);
  }
`;

export const ConfettiAnimation = styled(LottieAnimation)`
  position: absolute;
  // to keep centralized with screen (ignoring the header)
  transform: translateY(-6.5rem);
  width: 50rem;
  height: 50rem;
  flex: 0 0 50rem;

  body[data-mobile='true'] & {
    transform: translateY(0);
    width: 25rem;
    height: 25rem;
    flex: 0 0 25rem;
  }
`;
