import { ReactNode } from 'react';

import { RMText } from '@/components/RMText/RMText';

import { usePollExpirationTimer } from '../../hooks/poll-timer..hook';

import { CenterText, Header, HiddenFiller, Timer } from './PollVotingDialogHeader.styles';

export interface PollVotingDialogHeaderProps {
  LeftAdornment: ReactNode;
  RightAdornment: ReactNode;
  expireOn: number;
}

export function PollVotingDialogHeader({ LeftAdornment, RightAdornment, expireOn }: PollVotingDialogHeaderProps) {
  const timer = usePollExpirationTimer(expireOn);

  return (
    <Header>
      {LeftAdornment || <HiddenFiller>{RightAdornment}</HiddenFiller>}

      <CenterText>
        <RMText align="center" type="sans" size="xs" bold="medium" color="inverse-on-surface-primary">
          Poll ends in
        </RMText>
        <Timer>
          {[timer.hours, timer.minutes, timer.seconds].map((time, i) => (
            <RMText key={i} size="m" type="sans" bold color="danger-on-inverse-surface">
              {time}
            </RMText>
          ))}
        </Timer>
      </CenterText>

      {RightAdornment}
    </Header>
  );
}
