import { ReactNode } from 'react';

import { List } from './PromptReviewListRoot.styles';

type PromptReviewListRootProps = {
  children: ReactNode;
};

export function PromptReviewListRoot({ children }: PromptReviewListRootProps) {
  return <List>{children}</List>;
}
