import { styled } from '@linaria/react';

export const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-md);
  border-bottom: 1px solid var(--border-hairline);
  padding: var(--spacing-md);

  img {
    height: 5rem;
    width: 5rem;
    flex: 0 0 5rem;
    object-fit: cover;
    border-radius: var(--radius-minimal);
  }

  span {
    flex: 1;
  }

  &:after {
    position: absolute;
    content: ' ';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
`;

export const ItemCenter = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  gap: var(--spacing-sm);
`;

export const ItemActions = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  width: fit-content;
`;

export const Tag = styled.div`
  display: flex;
  flex: 1;
  width: fit-content;
  padding: var(--spacing-2xs) var(--spacing-xs);
  border-radius: var(--radius-minimal);
  border: 1px solid var(--primary);
`;
