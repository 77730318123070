import ArrowDownAnimationData from '@/@assets/arrow-down-lottie-animation.json';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { usePollExpirationTimer } from '@/modules/poll/hooks/poll-timer..hook';
import { genitiveCase } from '@/utils/genitiveCase';

import { ArrowAnimation, SharePollContent, Timer } from './CreatePollShare.styles';
interface CreatePollShareProps {
  firstName: string;
  expireOn: number;
}

export function CreatePollShare({ firstName, expireOn }: CreatePollShareProps) {
  const timer = usePollExpirationTimer(expireOn);

  return (
    <SharePollContent>
      <RMText align="center" size="m" type="sans" color="on-surface-primary" bold>
        Now, invite {genitiveCase(firstName)} friends and family to vote.
      </RMText>
      <RMSpacer spacing="sm" direction="column" />
      <RMText align="center" size="xs" type="sans" color="on-surface-primary">
        Voters will also be invited to this project and can see {genitiveCase(firstName)} stories.
      </RMText>
      <RMSpacer spacing="2xl" direction="column" />
      <RMText size="xs" type="sans" bold="medium" color="on-surface-tertiary">
        Poll ends in
      </RMText>
      <RMSpacer spacing="xs" direction="column" />
      <Timer>
        <RMText size="m" type="sans" bold color="danger">
          {timer?.hours}
        </RMText>
        <RMText size="m" type="sans" bold color="danger">
          {timer?.minutes}
        </RMText>
        <RMText size="m" type="sans" bold color="danger">
          {timer?.seconds}
        </RMText>
      </Timer>
      <RMSpacer spacing="2xl" direction="column" />
      <ArrowAnimation animationData={ArrowDownAnimationData} loop />
    </SharePollContent>
  );
}
