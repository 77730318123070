import { useState } from 'react';
import { PromptType } from '@remento/types/project';

import TextPromptThumbnailSrc from '@/assets/prompt-thumbnail.svg';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';

import { PromptReviewList } from '../components/PromptReviewList';
import {
  PromptDraftsStore,
  removePromptDraft,
  useIsPromptDraftEdited,
  usePromptDraft,
  usePromptDraftIds,
} from '../states/prompt-drafts.state';

interface PromptTemplateReviewListContainer {
  promptDraftsStore: PromptDraftsStore;
  onEditPrompt: (promptId: string) => void;
}

export function PromptTemplateReviewListContainer({
  promptDraftsStore,
  onEditPrompt,
}: PromptTemplateReviewListContainer) {
  const selectedPromptsIds = usePromptDraftIds(promptDraftsStore);
  return (
    <PromptReviewList.Root>
      {selectedPromptsIds.map((promptId) => (
        <PromptTemplateReviewListItemContainer
          key={promptId}
          promptDraftsStore={promptDraftsStore}
          promptId={promptId}
          onEditPrompt={onEditPrompt}
        />
      ))}
    </PromptReviewList.Root>
  );
}

interface PromptTemplateReviewListItemContainerProps {
  promptDraftsStore: PromptDraftsStore;
  promptId: string;
  onEditPrompt: (promptId: string) => void;
}

export function PromptTemplateReviewListItemContainer({
  promptDraftsStore,
  promptId,
  onEditPrompt,
}: PromptTemplateReviewListItemContainerProps) {
  const prompt = usePromptDraft(promptDraftsStore, promptId);
  const isEdited = useIsPromptDraftEdited(promptDraftsStore, promptId);

  const [deletePromptConfirmationOpen, setDeletePromptConfirmationOpen] = useState(false);

  const handleConfirmPromptDeletion = () => {
    removePromptDraft(promptDraftsStore, promptId);
    setDeletePromptConfirmationOpen(false);
  };

  if (!prompt) {
    return null;
  }

  return (
    <>
      <PromptReviewList.Item
        photoSrc={prompt.type === PromptType.PHOTO ? prompt.photo.url : TextPromptThumbnailSrc}
        question={prompt.question}
        isEdited={isEdited}
        onEditPrompt={() => onEditPrompt(promptId)}
        onRemovePrompt={() => setDeletePromptConfirmationOpen(true)}
      />
      {deletePromptConfirmationOpen && (
        <RMConfirmationModal
          open
          type="danger"
          title="Remove prompt"
          message="This action cannot be undone."
          cancelLabel="Cancel"
          confirmLabel="Delete Prompt"
          onCancel={() => setDeletePromptConfirmationOpen(false)}
          onConfirm={handleConfirmPromptDeletion}
        />
      )}
    </>
  );
}
