import { ReactNode } from 'react';

import { RMStack } from '@/components/RMStack/RMStack';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

interface AddPromptsHomeScreenProps {
  PromptTemplateInput: ReactNode;
  PromptTemplateTagList: ReactNode;
}

export function AddPromptsHomeScreen({ PromptTemplateInput, PromptTemplateTagList }: AddPromptsHomeScreenProps) {
  const isMobile = useIsMobileViewport();

  return (
    <RMStack direction="column" spacing="2xl">
      {PromptTemplateInput}

      <RMStack direction="column" spacing="2xl">
        <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} trackedOutCaps bold color="on-surface-tertiary">
          BROWSE QUESTIONS BY CATEGORY
        </RMText>

        {PromptTemplateTagList}
      </RMStack>
    </RMStack>
  );
}
