import { faClock } from '@fortawesome/pro-regular-svg-icons';

import pollIcon from '@/assets/poll-icon.svg';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';
import { useElementSize } from '@/hooks/useElementSize';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { usePollExpirationTimer } from '@/modules/poll/hooks/poll-timer..hook';

import { ClockIcon, Container, Image, Left, LeftTextContent, Right } from './PollListItem.styles';

export type PollListItemProps = {
  id: string;
  author: string;
  expireOn: number;
  votes: number;
  onClick: (id: string) => void;
  onShare: (id: string) => void;
};

export function PollListItem({ id, author, expireOn, votes, onClick, onShare }: PollListItemProps) {
  const isMobile = useIsMobileViewport();
  const [leftRef, leftSize] = useElementSize();
  const timer = usePollExpirationTimer(expireOn);

  return (
    <Container
      onClick={() => {
        onClick(id);
      }}
    >
      <Left ref={leftRef}>
        <Image src={pollIcon} alt="Poll image" variant="light" />
        <LeftTextContent>
          <RMText type="sans" size={isMobile ? 's' : 'm'} color="on-surface-primary">
            <b>{author}</b> started a prompt poll
          </RMText>
          {timer && (
            <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} bold color="danger">
              <ClockIcon icon={faClock} color="danger" />
              {timer.full} left
            </RMText>
          )}
          {timer != null && votes === 0 && (
            <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} color="on-surface-tertiary">
              Be the first to vote
            </RMText>
          )}
        </LeftTextContent>
      </Left>

      <Right onClick={(event) => event.stopPropagation()} style={{ height: leftSize.height }}>
        <RMButton size="small" background="outlined" onClick={() => onShare(id)}>
          Share
        </RMButton>
      </Right>
    </Container>
  );
}
