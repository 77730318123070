import { PropsWithChildren } from 'react';

import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { SelectWrapper } from './AddPromptsSelectType.styles';

type AddPromptsSelectType = PropsWithChildren<{
  hasTopics: boolean;
  onClose?: () => void;
}>;

export function AddPromptsSelectType({ hasTopics, onClose, children }: AddPromptsSelectType) {
  const mobile = useIsMobileViewport();

  return (
    <>
      <RMDialog.Header
        centerTitle={true}
        title={hasTopics ? 'Add prompts' : 'Add more photos'}
        rightAdornment={<RMCloseButton id="choose-prompt-type-dialog-close-button" onClick={onClose} />}
      ></RMDialog.Header>
      <RMDialog.Body>
        <RMDialog.Transition>
          <SelectWrapper data-mobile={mobile}>{children}</SelectWrapper>
        </RMDialog.Transition>
      </RMDialog.Body>
    </>
  );
}
