import { useCallback, useState } from 'react';

import photoAnimation from '@/assets/photo-prompt.lottie.json';
import pollAnimation from '@/assets/poll-hover.lottie.json';
import questionAnimation from '@/assets/question-prompt.lottie.json';
import { RMDialog } from '@/components/RMDialog';
import { CreatePollContainer } from '@/modules/poll/containers/CreatePoll.container';
import { closePromptDialog, setAddPromptDialogStep } from '@/modules/project/states/project-dialog-panel.state';
import { useServices } from '@/Services';
import { useProjectQuery } from '@/services/api/project';
import { captureException } from '@/utils/captureException';
import { FilestackHandle, openFilestackPicker } from '@/utils/filestack';

import { AddPromptsSelectType } from '../components/AddPromptsSelectType/AddPromptsSelectType';
import { AddPromptsSelectTypeCard } from '../components/AddPromptsSelectTypeCard/AddPromptsSelectTypeCard';
import { ProjectDialogPanelStore, useProjectDialogPanelState } from '../states/project-dialog-panel.state';
import { PromptDraftsStore } from '../states/prompt-drafts.state';

import { AddTextPromptsContainer } from './AddTextPrompts.container';
import { ReviewPhotoPromptsContainer } from './ReviewPhotoPrompts.container';

type AddPromptsDialogContainerProps = {
  promptDraftsStore: PromptDraftsStore;
  panelStore: ProjectDialogPanelStore;
  projectId: string;
  onPromptsAdded?: () => void;
  onSelectPhotoPrompt?: () => void;
};

export function AddPromptsDialogContainer({
  promptDraftsStore,
  panelStore,
  projectId,
  onPromptsAdded,
}: AddPromptsDialogContainerProps) {
  const [uploadedPhotos, setUploadedPhotos] = useState<FilestackHandle[] | null>(null);

  const panelState = useProjectDialogPanelState(panelStore);
  const { promptAnalyticsService } = useServices();

  const projectQuery = useProjectQuery(projectId);
  const hasTopics = projectQuery.data?.configuration.topics && projectQuery.data?.configuration.topics.length > 0;

  const handleClose = useCallback(() => {
    setUploadedPhotos(null);
    closePromptDialog(panelStore);
    promptAnalyticsService.onPromptBankActionPerformed('close');
  }, [panelStore, promptAnalyticsService]);

  const handleUploadPhotoPrompts = useCallback(async () => {
    promptAnalyticsService.onUpcomingPromptsNewPromptPressed('photo');
    promptAnalyticsService.onPromptPhotoPickerOpened('quick-action');

    setAddPromptDialogStep(panelStore, 'photo');

    try {
      await openFilestackPicker({
        accept: ['image/png', 'image/jpeg', 'image/webp'],
        maxSize: 1024 * 1024 * 20,
        maxFiles: 20,
        onUploadDone: ({ filesUploaded }) => {
          if (filesUploaded.length === 0) {
            handleClose();
            return;
          }

          promptAnalyticsService.onPromptPhotoSelected();
          setUploadedPhotos(filesUploaded);
        },
        onCancel: handleClose,
      });
    } catch (error) {
      captureException(error, true);
    }
  }, [handleClose, panelStore, promptAnalyticsService]);

  if (panelState.type !== 'add-prompt') {
    return null;
  }

  if (panelState.step === 'choose-type' || panelState.step === 'text') {
    const chooseTypeVariant = hasTopics ? 'extra-large' : 'regular';
    const variant = panelState.step === 'choose-type' ? chooseTypeVariant : 'full-screen';

    return (
      <RMDialog.Root key={variant} variant={variant} open={panelState.type === 'add-prompt'}>
        <RMDialog.Content>
          {panelState.step == 'choose-type' && (
            <AddPromptsSelectType hasTopics={hasTopics ?? false} onClose={handleClose}>
              <AddPromptsSelectTypeCard
                title={hasTopics ? 'Upload a photo' : 'Photo upload'}
                text={hasTopics ? 'Add a photo to inspire a story.' : 'Select up to 20 photos to upload'}
                lottieSrc={photoAnimation}
                onClick={handleUploadPhotoPrompts}
              />

              {hasTopics && (
                <AddPromptsSelectTypeCard
                  id="select-prompt-type-dialog-text-button"
                  title="Add a question"
                  text="Write your own or browse questions written by our team of experts."
                  lottieSrc={questionAnimation}
                  onClick={() => setAddPromptDialogStep(panelStore, 'text')}
                />
              )}

              {hasTopics && (
                <AddPromptsSelectTypeCard
                  title="Poll your friends and family"
                  text="Invite others to vote on the next prompt."
                  lottieSrc={pollAnimation}
                  onClick={() => setAddPromptDialogStep(panelStore, 'poll')}
                />
              )}
            </AddPromptsSelectType>
          )}
          {panelState.step === 'text' && (
            <AddTextPromptsContainer
              projectId={projectId}
              panelStore={panelStore}
              promptDraftsStore={promptDraftsStore}
              onPromptsAdded={onPromptsAdded}
              onBack={() => setAddPromptDialogStep(panelStore, 'choose-type')}
              onClose={handleClose}
            />
          )}
        </RMDialog.Content>
      </RMDialog.Root>
    );
  }

  if (panelState.step === 'poll') {
    return (
      <CreatePollContainer
        projectId={projectId}
        onPromptsAdded={onPromptsAdded}
        onBack={() => setAddPromptDialogStep(panelStore, 'choose-type')}
        onClose={handleClose}
      />
    );
  }

  return (
    <ReviewPhotoPromptsContainer
      projectId={projectId}
      photos={uploadedPhotos ?? []}
      open={uploadedPhotos !== null}
      onClose={handleClose}
      onPromptsAdded={onPromptsAdded}
    />
  );
}
