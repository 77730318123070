import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const SelectWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  gap: var(--spacing-xl);

  &[data-mobile='true'] {
    gap: var(--spacing-md);
    flex-wrap: wrap;
  }
`;
